import Utility from "./utility";
import uniq from "lodash/uniq";

const TagCounterConfig = {};
TagCounterConfig.lowCount = -1;
TagCounterConfig.highCount = -1;

export default class TagCounter {
  static config = TagCounterConfig;

  constructor($element) {
    this.$element = $element;
    this.$target.on("input", (event) => this.update(event));
    this.update();
  }

  update() {
      let high = (-1 === TagCounter.config.highCount) ?
	  '∞' :
	  TagCounter.config.highCount;

      this.$element.find(".tag-count").text(`${this.tagCount} / ${high} tags`);

      if (-1 !== TagCounter.config.lowCount
	  && -1 !== TagCounter.config.highCount) {
	  this.$element.find("img").attr("src", `/images/${this.iconName}.png`);
      }
  }

  get $target() {
      return $(this.$element.attr("data-for"));
  }

  get tagCount() {
    let tagString = this.$target.val().toLowerCase();
    let tags = uniq(Utility.splitWords(tagString));
    return tags.length;
  }

  get iconName() {
    if (this.tagCount < TagCounter.config.lowCount) {
      return "blobglare";
    } else if (this.tagCount >= TagCounter.config.lowCount &&
	       this.tagCount < TagCounter.config.highCount) {
      return "blobthinkingglare";
    } else {
      return "blobaww";
    }
  }

  static initialize() {
    $("[data-tag-counter]").toArray().forEach(element => {
      new TagCounter($(element));
    });
  }
}

$(TagCounter.initialize);
